body {
  margin: 0;
  width: 100%;
}

a {
  color: blue;
  text-decoration: none;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}

*:focus {
  outline: none;
}